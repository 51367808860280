.project-info{
    width: 100%;
    height: 100%;
    border: 3px solid #333;
    overflow: hidden;

    .project-overlay{
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.15s;
        text-align: center;
        background-color: #3f3f48e0;
        overflow: hidden;

        p{
            font-size: 2rem;
            letter-spacing: 5px;
            text-transform: uppercase;
            font-weight: 100;
            /* background-color: var(--primary-indigo); */
        }

        &:hover{
            background-color: #3f3f48e0;
        }
    }
}
.hidden{
    display: none !important;
}

.mobile .overlay-mobile {
    width: 100%;
    background: #3f3f48e0;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    p{
        font-size: 0.85rem;
        width: 75%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hidden{
        display: inline;
    }

    img{
        width: 25px;
        height: 25px;
        max-width: 100%;
        max-height: 95%;
        cursor: pointer;
    }

    .git-icon{
        margin-left: 5px;
    }

    .earth-icon{
        margin-right: 5px;
    }

    .disabled{
        pointer-events: none;
        img {
            filter: brightness(25%);
        }
    }

}



@media (hover: hover) {
    button:hover {
        background-color: #3f3f48e0;

    }
  }


@media screen and (max-width:1200px){
 
}
@media screen and (min-width:1025px) and (max-width:1199px){

}

@media screen and (min-width:751px) and (max-width:1024px){

    .project-info .project-overlay p{
        font-size: 1.25rem;
    }

    .project-info{
        width: 100%;
        height: 90%;
        border: 3px solid #333;
        overflow: hidden;
    }

}
@media screen and (min-width: 551px) and (max-width:750px){

    .project-info .project-overlay p{
        font-size: 1rem;
    }
    
}

@media screen and (min-width: 600px) and (max-width:550px){

    .project-info .project-overlay p{
        font-size: 1rem;
    }
    
}

@media screen and (max-width:599px){

    .project-info .project-overlay{
        justify-content: flex-start !important;
        align-items: flex-start !important;
        background-color: transparent;
        
        .hidden{
            display: flex !important;
            pointer-events:none;
    
            p{
                align-self: flex-start;
            }
        }

        &:hover{
            background-color: transparent;
        }
    }
 
}

@media (max-width: 850px) and (max-height: 450px)and (orientation:landscape) {

    .project-info .project-overlay{
        justify-content: flex-start !important;
        align-items: flex-start !important;
        background-color: transparent;
        
        p{
            font-size: 0.75rem;
            width: 75%;
        }

        &:hover{
            background-color: transparent;
        }
    }
}

