#project-display{
    margin: 0 auto;
    height: 100%;
    width: 80%;

    .display-container{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(34, 34, 34);
        height: 80%;
        max-height: 500px;
        border: 3px solid rgb(34, 34, 34);
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        /* box-shadow: 0px 0px 20px 10px#888; */
        overflow: hidden;

        img{
            max-width: 100%;
            max-height: 95%;
        }
    }

    .display-controls{
        border-top: 3px solid #333;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: rgb(34, 34, 34);
        padding: 15px 0 20px;

        img{
            width: 40px;
            height: 40px;
            cursor: pointer;
        }

        button{
            background: none;
            border: 3px solid transparent;
            outline: none;
            &:hover{
                border-bottom: 3px solid var(--primary-blue);
            }

            .left-arrow{
                transform: rotate(180deg);
            }
        }

        a{
            border: 3px solid transparent;
            &:hover{
                border-bottom: 3px solid var(--primary-indigo);
            }
        }

        .disabled{
            pointer-events: none;
            img {
                filter: brightness(25%);
            }
        }

        .earth-icon{
            transition: all 500ms ease-in-out;
        }
    }
}


@media screen and (min-width:1200px){
    .display-container{
        height: 90%;
        width: 100%;
        max-height: 500px;
    }

}
@media screen and (min-width:1400px){
    .display-container{
        height: 80%;
        width: 100%;
        max-height: 525px;
    }

}
@media screen and (min-width:2000px){
    .display-container{
        height: 80%;
        max-height: 525px;
    }
}

@media screen and (min-width:750px) and (max-width:1024px){

    #project-display{
        width: 90%;
    }
}
@media screen and (min-width: 551px) and (max-width:749px){
    #project-display{
        width: 65%;
    }
}

@media screen and (min-width: 481px) and (max-width:550px){
    #project-display{
        width: 65%;
    }
}
