#nav{
    width: 60px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: var(--primary-light-blue);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-right:none;

    position: fixed;
    right: 0;
    top: 80px;
    z-index: 999;

    a{
        padding-top: 5px;
        cursor: pointer;
    }

    img{
        height: 40px;
        width: auto;
    }
}

@media screen and (max-width:1024px){
    #nav{
        width: 50px;
        height: 200px;
        top: 30px;

        a{
            padding-top: 3px;
        }

        img{
            height: 30px;
            width: auto;
        }
    }
}

@media screen and (max-width:751px){
    #nav{
        top: 40px;
    }
}

@media screen and (max-width:749px){
    #nav{
       display: none;
    }

}
