#header{
    width: 100%;
    height: 65vh;
    max-height: 500px;
    background: var(--header-background);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

#header .header-container{
    height: 80%;
    width: 90%;
    max-width: var(--screen-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

}

#header .header-container .header-image{
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

#header .header-container .header-image img{
    max-height: 400px;
    max-width: auto;
}

.header-content{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-content div{
    width: 80%;
    margin: 0 auto 2rem;
    align-self: start;
}

.header-content div h1{
    font-weight: 800;
    font-size: 5rem;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 1.1;
}
.header-content div h1:last-child{
    font-size: 4rem;
}

.header-content div:nth-child(2){
    font-weight: 100;
    letter-spacing: 2px;
}

.header-content .header-info p{
    font-size: 1.1rem;
}



.header-content img{
    transform: rotate(90deg);
    height: 60px;
}


@media screen and (min-width:1200px){
    #header{
        height: 50vh;
        min-height: 500px;
        max-height: 550px;
    }
    .header-content div h1{
        font-size: 4.5rem;
        letter-spacing: 5px;
    }
    
    .header-content div h1:last-child{
        font-size: 3.5rem;
    }
}

@media screen and (min-width:1440px){

    #header{
        height: 50vh;
    }
}

@media screen and (min-width:2000px){
    #header{
        height: 45vh;
    }
}


@media screen and (min-width:1025px) and (max-width:1199px){
    #header{
        height: 75vh;
        min-height: 400px;
        max-height: 500px;
    }
    
    #header .header-container{
        height: 80%;
        align-items: center;
        justify-content: center;    
    }
    
    #header .header-container .header-image{
        width: 70%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    #header .header-container .header-image img{
        max-height: 340px;
    }
    
    
    .header-content{
        align-items: stretch;
        margin-left: 1.5rem;
    }
    
    .header-content div{
        width: 100%;
        margin: 0 auto 1rem;
    }
    
    .header-content div h1{
        font-size: 4.25rem;
    }
    .header-content div h1:last-child{
        font-size: 3.50rem;
    }
    
    .header-content .header-info{
        text-align: left;
        margin: 1rem 0;
        width: 80%;
    }
    .header-content .header-info p{
        font-size: 1rem;
    }

    
    .header-content img{
        margin-left: 2rem;
    }
}

@media screen and (min-width:771px) and (max-width:1024px){
    #header{
        height: 70vh;
        min-height: 450px;
        max-height: 475px;
    }
    
    #header .header-container{
        width: 100%;
        height: 80%;
        align-items: center;
        justify-content: center;    
    }
    
    #header .header-container .header-image{
        width: 70%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    #header .header-container .header-image img{
        max-height: 345px;
    }
    
    
    .header-content{
        align-items: stretch;
        margin-left: 1.5rem;
    }
    
    .header-content div{
        width: 100%;
        margin: 0 auto 1rem;
    }
    
    .header-content div h1{
        font-size: 3.25rem;
    }
    .header-content div h1:last-child{
        font-size: 2.50rem;
    }
    
    .header-content .header-info{
        text-align: left;
        margin: 1rem 0;
        width: 80%;
    }
    .header-content .header-info p{
        font-size: 0.85rem;
    }

    
    .header-content img{
        margin-left: 2rem;
    }
    

}

@media screen and (max-width:770px){
    #header{
        width: 100%;
        height: 50vh;
        background:  
            linear-gradient(
                rgba(54, 54, 54, 0.45), 
                rgba(31, 31, 31, 0.45)
                ),
          url('../assets/images/header_img.jpg') 
          no-repeat center center /cover;
    }

    #header .header-container .header-image{
        display: none;
    }
}


@media screen and (min-width: 551px) and (max-width:770px){
    #header{
        height: 60vh;
    }
    
    #header .header-container{
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    
    .header-content{
        width: 100%;
        text-align: center;
    }
    
    .header-content div{
        width: 90%;        
        margin: 0 auto;
        align-self: center;
        margin-bottom: 1rem;
    }
    
    .header-content div h1{
        font-size: 3.75rem;
        line-height: 1.25;
    }
    .header-content div h1:last-child{
        font-size: 3.15rem;
    }
    
    .header-content div:nth-child(2){
        padding-bottom: 15px;
    }

    .header-content div:nth-child(2) p{
        font-size: 1rem;
    }
    
    
    .header-content img{
        height: 50px;
    }
    
    

}

@media screen and (min-width: 481px) and (max-width:550px){
    #header{
        height: 60vh;
    }
    
    #header .header-container{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    
    .header-content{
        width: 100%;
        text-align: center;
    }
    
    .header-content div{
        width: 90%;        
        margin: 0 auto;
        align-self: center;
        margin-bottom: 1rem;
    }
    
    .header-content div h1{
        font-size: 3.75rem;
        line-height: 1.25;
    }
    .header-content div h1:last-child{
        font-size: 3.15rem;
    }
    
    .header-content div:nth-child(2){
        padding-bottom: 15px;
    }

    .header-content div:nth-child(2) p{
        font-size: 1rem;
    }
    
    
    .header-content img{
        height: 50px;
    }
    
    
}

@media screen and (max-width:480px){
    
    #header .header-container{
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    
    .header-content{
        height: 100%;
        width: 100%;
        text-align: center;
    }
    
    .header-content div{
        width: 100%;
        margin: 0 auto;
    }
    
    .header-content div h1{
        font-size: 3.50rem;
    }
    .header-content div h1:last-child{
        font-size: 2.75rem;
        padding-bottom: 25px;
    }

    .header-content div:nth-child(2){
        padding-bottom: 25px;
    }
    
    .header-content div:nth-child(2) p{
        font-size: 0.85rem;
    }
    
    
    .header-content img{
        height: 40px;
    }

}

@media screen and (max-width:360px){
 
    
    .header-content div h1{
        font-size: 3rem;
    }
    .header-content div h1:last-child{
        font-size: 2.15rem;
        padding-bottom: 15px;
    }
    
    .header-content div:nth-child(2){
        padding-bottom: 15px;

    }

    .header-content div:nth-child(2) p{
        font-size: 0.8rem;
    }
    
    
    .header-content img{
        height: 25px;
    }




}


@media (max-width: 750px) and (orientation:landscape) {
    #header{
        height: 80vh;
    }
    
    #header .header-container{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    
    .header-content{
        width: 100%;
        text-align: center;
    }
    
    .header-content div{
        width: 90%;        
        margin: 0 auto;
        align-self: center;
        margin-bottom: 1rem;
    }
    
    .header-content div h1{
        font-size: 3.75rem;
        line-height: 1.25;
    }
    .header-content div h1:last-child{
        font-size: 3.15rem;
    }
    
    .header-content div:nth-child(2){
        padding-bottom: 15px;
    }

    .header-content div:nth-child(2) p{
        font-size: 1rem;
    }
    
    
    .header-content img{
        height: 50px;
    }


}

@media (max-width: 850px) and (max-height: 400px)and (orientation:landscape) {

    #header{
        height: 90vh;
        background:  
            linear-gradient(
                rgba(54, 54, 54, 0.45), 
                rgba(31, 31, 31, 0.45)
                ),
          url('../assets/images/header_img.jpg') 
          no-repeat center center /cover;
    }

    #header .header-container .header-image{
        display: none;
    }
    #header{
        height: 80vh;
    }
    
    #header .header-container{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    
    .header-content{
        width: 100%;
        text-align: center;
    }
    
    .header-content div{
        width: 90%;        
        margin: 0 auto;
        align-self: center;
        margin-bottom: 1rem;
    }
    
    .header-content div h1{
        font-size: 3.75rem;
        line-height: 1.25;
    }
    .header-content div h1:last-child{
        font-size: 3.15rem;
    }
    
    .header-content div:nth-child(2){
        padding-bottom: 15px;
    }

    .header-content div:nth-child(2) p{
        font-size: 1rem;
    }
    
    
    .header-content img{
        height: 50px;
    }

}



@media (max-width: 570px) and (orientation:landscape) {
    #header{
        height: 100vh;
    }
}
