.container-bar{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    #bar-title{
        font-size: 1.5rem;
        letter-spacing: 1px;
    }
}


@media (max-width: 850px) and (orientation:landscape) {
    .container-bar{
        #bar-title{
            font-size: 1.75rem;
        }
    }
}