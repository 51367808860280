#timeline{
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}




@media screen and (max-width:1200px){
 
}
@media screen and (min-width:1025px) and (max-width:1199px){

    #timeline{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
    }
}

@media screen and (min-width:751px) and (max-width:1024px){

    #timeline{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
    }

}
/* @media screen and (min-width: 551px) and (max-width:750px){

} */



@media screen and (min-width: 481px) and (max-width:750px){
    #timeline{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
    }

    #timeline .timeline-education{
        padding-bottom: 5px;
    }
}

@media screen and (max-width:480px){
    #timeline{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
    }

    #timeline .timeline-education{
        padding-bottom: 5px;
    }
}

@media screen and (max-width:360px){
    #timeline{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }
    /* #timeline .timeline-education{
    } */

}

@media (max-width: 813px) and (orientation:landscape) {
    #timeline{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
        max-height: 185px;
    }

}
