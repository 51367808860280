#skills{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .skills-progress{
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
    }

    .skills-title{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 2rem;

        h1{
            font-size: 5rem;
            text-transform: uppercase;
            letter-spacing: 10px;
            font-weight: 100;
        }
    }

    .skills-icon{
        height: 100%;
        width: 10%;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 6.5rem;

        h1{
            font-size: 22rem;
            font-weight: 500;
            color: var(--primary-indigo);
        }
    }

    .skills-bar{
        height: 60%;
        width: 40%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        gap: 5px;
    }

}


@media screen and (min-width:1200px){
    #skills{
        padding-bottom: 3rem;

        .skills-title{
            h1{
                font-size: 6rem;    
            }
        }

        .skills-icon {
            h1{
                font-size: 25rem;
                margin-right: 0.5rem;
            }
        }
    }
}


@media screen and (min-width:2000px){
    #skills{
        .skills-icon {
            h1{
                margin-right: 1rem;
            }
        }
    }
}



@media screen and (min-width:1025px) and (max-width:1199px){

    #skills{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:space-evenly;

        .skills-title{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 2rem;

            h1{
                margin-top: 0;
                font-size: 5.25rem;
                text-transform: uppercase;
                letter-spacing: 10px;
                font-weight: 100;
            }
        }

        .skills-icon{
            height: 100%;
            width: 10%;
            align-self: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 6.5rem;

            h1{
                font-size: 22rem;
            }
        }
        
    }
}



@media screen and (min-width:751px) and (max-width:1024px){
    #skills{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .skills-title{
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 2rem;

            h1{
                margin-top: 0;
                font-size: 5rem;
                text-transform: uppercase;
                letter-spacing: 10px;
                font-weight: 100;
                margin-bottom: 1rem;
            }
        }
        .skills-icon{
            display: none;

            h1{
                display: none;
            }
        }

        .skills-bar{
            margin-top: 1rem;
            height: 70%;
            width: 70%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap: 5px;
        }
    }
}

@media screen and (max-width:750px){
    #skills{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .skills-title{
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 2rem;

            h1{
                margin-top: 0;
                font-size: 3.75rem;
                text-transform: uppercase;
                letter-spacing: 10px;
                font-weight: 100;
            }
        }

        .skills-icon{
            display: none;
            h1{
                display: none;
            }
        }

        .skills-bar{
            margin-top: 1rem;
            height: 70%;
            width: 80%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap: 5px;
        }
   
    }
}


@media screen and (max-width:480px){
    #skills{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .skills-title{
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 2rem;

            h1{
                margin-top: 0;
                font-size: 3.75rem;
                text-transform: uppercase;
                letter-spacing: 10px;
                font-weight: 100;
            }
        }

        .skills-icon{
            display: none;
            h1{
                display: none;
            }
        }

        .skills-bar{
            width: 90%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap: 5px;
        }
   
    }
}

@media (max-width: 850px) and (orientation:landscape) {
    #skills{
        height: 100%;
        width: 100%;

        .skills-title{
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 2rem;
            padding-top: 1rem;

            h1{
                margin-top: 0;
                font-size: 4.5rem;
                padding-bottom: 20px;
                text-transform: uppercase;
                letter-spacing: 10px;
                font-weight: 100;
            }
        }

        .skills-icon{
            display: none;
        }

        .skills-bar{
            height: 80%;
        }
    }
}