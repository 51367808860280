#footer{
    width: 100%;
    height: 40vh;
    max-height: 350px;
    min-height: 325px;
    background: var(--primary-indigo);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

#footer .footer-container{
    margin: 0 auto;
    height: 80%;
    padding-top: 2rem;
    width: 80%;
    max-width: var(--screen-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#footer .footer-container .footer-top{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#footer .footer-img{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer .footer-img img{
    border-radius: 15%;
    max-width: 150px;
    max-height: 150px;
}

#footer .footer-tagline{
    height: 100%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer .footer-tagline h3{
    width: 100%;
    line-height: 1.2;
    font-size: 2.50rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 800;
    text-align: left;
}

#footer .footer-container .footer-top .footer-info{
    width: 25%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding-left: 3rem;
}

#footer .footer-container .footer-top .footer-info p{
    font-size: 0.90rem;
    font-weight: 100;
    letter-spacing: 1px;
}



#footer .footer-container .footer-bot{
    height: 100%;
    width: 100%;
    display: flex;
    padding-top: 2rem;
    align-items: center;
    justify-content: center;
}


#footer .footer-container .footer-bot .footer-logo,
#footer .footer-container .footer-bot .footer-links{
    height: 100%;
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer .footer-container .footer-bot .footer-logo h3{
    padding-left: 10%;
    font-weight: 800;
    font-size: 1.45rem;
    text-transform: uppercase;
    letter-spacing: 5px;
    border: 2px solid transparent;
}

#footer .footer-container .footer-bot .footer-links{
    width: 50%;
    justify-content: space-between;
    padding-left: 5rem;
}

#footer .footer-container .footer-bot .footer-links a{
    text-decoration: none;
    letter-spacing: 3px;
    font-weight: 100;
    color: #fff;
    border: 2px solid transparent;
}

#footer .footer-container .footer-bot .footer-links a:visited{
    color: #fff;
}

#footer .footer-container .footer-bot .footer-links a:hover{
    border-bottom: 2px solid #03BFF8;
}




@media screen and (min-width:1036px) and (max-width:1199px){
    #footer{
        height: 50vh;
        max-height: 325px;
        min-height: 310px;
    }
    
    #footer .footer-container{
        width: 90%;
    }
    
    #footer .footer-img img{
        max-width: 140px;
        max-height: 140px;
    }
    
    #footer .footer-tagline h3{
        font-size: 2.15rem;
    }
    
    
    
    #footer .footer-container .footer-bot{
        padding-top: 1.75rem;
    }
    
    
    #footer .footer-container .footer-bot .footer-logo,
    #footer .footer-container .footer-bot .footer-links{
        width: 45%;
        padding-left: 5%;
    }
    
    #footer .footer-container .footer-bot .footer-logo h3{
        font-size: 1.35rem;
    }
    
    #footer .footer-container .footer-bot .footer-links{
        width: 55%;
        justify-content: space-around;
        padding-left: 3rem;
    }
    
    #footer .footer-container .footer-bot .footer-links a{
        font-size: 0.95rem;
    }

    

}

@media screen and (min-width:887px) and (max-width:1035px){
    #footer{
        height: 50vh;
        max-height: 330px;
        min-height: 320px;
    }
    
    #footer .footer-container{
        width: 90%;
    }
    
    #footer .footer-img img{
        max-width: 135px;
        max-height: 135px;
    }
    
    #footer .footer-tagline h3{
        font-size: 1.80rem;
    }
    
    
    
    #footer .footer-container .footer-bot{
        padding-top: 1.75rem;
    }
    
    
    #footer .footer-container .footer-bot .footer-logo,
    #footer .footer-container .footer-bot .footer-links{
        width: 45%;
        padding-left: 5%;
    }
    
    #footer .footer-container .footer-bot .footer-logo h3{
        font-size: 1.25rem;
    }
    
    #footer .footer-container .footer-bot .footer-links{
        width: 55%;
        justify-content: space-around;
        padding-left: 3rem;
    }
    
    #footer .footer-container .footer-bot .footer-links a{
        font-size: 0.95rem;
    }
}    

@media screen and (min-width:764px) and (max-width:886px){
    #footer{
        height: 50vh;
        max-height: 325px;
        min-height: 300px;
    }
    
    #footer .footer-container{
        width: 90%;
    }
    
    #footer .footer-img img{
        max-width: 110px;
        max-height: 110px;
    }
    
    #footer .footer-tagline h3{
        font-size: 1.50rem;
    }
    
    #footer .footer-container .footer-top .footer-info p{
        font-size: 0.80rem;
    }
    
    
    
    #footer .footer-container .footer-bot{
        padding-top: 1.5rem;
    }
    
    
    #footer .footer-container .footer-bot .footer-logo,
    #footer .footer-container .footer-bot .footer-links{
        width: 40%;
        padding-left: 5%;
    }
    
    #footer .footer-container .footer-bot .footer-logo h3{
        font-size: 1.25rem;
    }
    
    #footer .footer-container .footer-bot .footer-links{
        width: 60%;
        justify-content: space-around;
        padding-left: 1.2rem;
    }
    
    #footer .footer-container .footer-bot .footer-links a{
        font-size: 0.90rem;
    }
}    


@media screen and (min-width: 546px) and (max-width:763px){
    #footer{
        height: 50vh;
        max-height: 350px;
        min-height: 345px;
    }
    
    #footer .footer-container{
        height: 90%;
        padding-top: 1rem;
        width: 80%;
    }
    
    #footer .footer-container .footer-top{
        height: 100%;
        width: 100%;
        max-height: 200px;
        min-height: 190px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    #footer .footer-img{
        padding-bottom: 0.1rem;
    }
    
    #footer .footer-img img{
        border-radius: 15%;
        max-width: 125px;
        max-height: 125px;
    }
    
    #footer .footer-tagline{
        width: 100%;
        height: 25%;
    }
    
    #footer .footer-tagline h3{
        width: 100%;
        font-size: 2rem;
        text-align: center;
    }
    
    #footer .footer-container .footer-top .footer-info{
        display: none;
    }
    
    
    #footer .footer-container .footer-bot{
        height: 100%;
        width: 100%;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    
    #footer .footer-container .footer-bot .footer-logo,
    #footer .footer-container .footer-bot .footer-links{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    #footer .footer-container .footer-bot .footer-logo{
        min-height: 35px;
    }

    #footer .footer-container .footer-bot .footer-logo h3{
        text-align: center;
        font-size: 1.35rem;
        padding-left: 0;
    }
    
    #footer .footer-container .footer-bot .footer-links{
        width: 100%;
        padding-left: 0;
        max-height: 120px;
        min-height: 110px;
    }
    
    #footer .footer-container .footer-bot .footer-links a{
        text-decoration: none;
        letter-spacing: 3px;
        font-weight: 100;
        color: #fff;
        border: 2px solid transparent;
        padding: 5px 0;
    }
}

@media screen and (min-width: 481px) and (max-width:545px){
    #footer{
        height: 45vh;

    }
    
    #footer .footer-container{
        height: 90%;
        padding-top: 1rem;
        width: 80%;
    }
    
    #footer .footer-container .footer-top{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 175px;
        max-height: 180px;
    }
    #footer .footer-img{
        padding-bottom: .1rem;
    }
    
    #footer .footer-img img{
        border-radius: 15%;
        max-width: 125px;
        max-height: 125px;
    }
    
    #footer .footer-tagline{
        width: 100%;
        height: 30%;
    }
    
    #footer .footer-tagline h3{
        width: 100%;
        font-size: 1.5rem;
        text-align: center;
    }
    
    #footer .footer-container .footer-top .footer-info{
        display: none;
    }
    
    
    #footer .footer-container .footer-bot{
        height: 100%;
        width: 100%;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    
    #footer .footer-container .footer-bot .footer-logo,
    #footer .footer-container .footer-bot .footer-links{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    #footer .footer-container .footer-bot .footer-logo h3{
        text-align: center;
        font-size: 1.25rem;
        padding-left: 0;
    }
    
    #footer .footer-container .footer-bot .footer-links{
        width: 100%;
        padding-left: 0;
    }
    
    #footer .footer-container .footer-bot .footer-links a{
        text-decoration: none;
        letter-spacing: 3px;
        font-weight: 100;
        color: #fff;
        border: 2px solid transparent;
        padding: 5px 0;
        text-align: center;
    }
    

}


@media screen and (max-width:480px){
    #footer{
        height: 50vh;
        max-height: 355px;
        min-height: 340px;
    }
    
    #footer .footer-container{
        height: 90%;
        padding-top: 0;
        width: 90%;
    }
    
    #footer .footer-container .footer-top{
        height: 60%;
        min-height: 155px;
        max-height: 180px;
        width: 100%;
        margin: .75rem 0 ;
        display: flex;
        flex-direction: column;
    }
    #footer .footer-img{
        /* padding: 1rem 0 0.5rem; */
    }
    
    #footer .footer-img img{
        border-radius: 15%;
        max-width: 125px;
        max-height: 125px;
    }
    
    #footer .footer-tagline{
        width: 100%;
        height: 25%;
    }
    
    #footer .footer-tagline h3{
        width: 100%;
        line-height: 1;
        font-size: 1.45rem;
        text-align: center;
    }
    
    #footer .footer-container .footer-top .footer-info{
        display: none;
    }
    
    
    #footer .footer-container .footer-bot{
        height: 45%;
        width: 100%;
        padding-top: 0.05rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    
    #footer .footer-container .footer-bot .footer-logo,
    #footer .footer-container .footer-bot .footer-links{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #footer .footer-container .footer-bot .footer-logo{
        min-height: 30px;
    }
    
    #footer .footer-container .footer-bot .footer-logo h3{
        text-align: center;
        line-height: 1;
        font-size: 1.15rem;
        padding-left: 0;
    }
    
    #footer .footer-container .footer-bot .footer-links{
        width: 100%;
        min-height: 105px;
        max-height: 115px;
        padding-left: 0;
    }
    
    #footer .footer-container .footer-bot .footer-links a{
        text-decoration: none;
        letter-spacing: 3px;
        font-weight: 100;
        color: #fff;
        border: 2px solid transparent;
        padding: 5px 0;
        text-align: center;
    }
    
}

@media screen and (max-width:360px){
    #footer{
        height: 65vh;
        max-height: 325px;
        min-height: 300px;
    }
    
    #footer .footer-container{
        height: 90%;
        padding-top: 0;
        width: 90%;
    }
    
    #footer .footer-container .footer-top{
        height: 50%;
        max-height: 160px;
        min-height: 145px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    #footer .footer-img{
        padding: 0  0 0.5rem;
    }
    #footer .footer-img img{
        border-radius: 15%;
        max-width: 100px;
        max-height: 100px;
    }
    
    #footer .footer-tagline{
        width: 100%;
        max-height: 40px;
    }
    
    #footer .footer-tagline h3{
        width: 100%;
        font-size: 1.2rem;
        text-align: center;
    }
    
    #footer .footer-container .footer-top .footer-info{
        display: none;
    }
    
    #footer .footer-container .footer-bot{
        height: 40%;
        width: 100%;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    
    #footer .footer-container .footer-bot .footer-logo,
    #footer .footer-container .footer-bot .footer-links{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #footer .footer-container .footer-bot .footer-logo{
        min-height: 25px;
    }
    
    
    #footer .footer-container .footer-bot .footer-logo h3{
        text-align: center;
        line-height: 1;
        font-size: 1.15rem;
        padding-left: 0;
    }
    
    #footer .footer-container .footer-bot .footer-links{
        width: 100%;
        min-height: 105px;
        max-height: 110px;
        padding-left: 0;
    }
    
    #footer .footer-container .footer-bot .footer-links a{
        text-decoration: none;
        letter-spacing: 3px;
        font-weight: 100;
        color: #fff;
        border: 2px solid transparent;
        padding: 5px 0;
        text-align: center;
    }
 
    
}


@media (max-width: 850px) and (max-height: 400px)and (orientation:landscape) {
    #footer{
        height: 70vh;
    }
    
    #footer .footer-container{
        height: 90%;
        padding-top: 1rem;
        width: 80%;
    }
    
    #footer .footer-container .footer-top{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-height: 175px;
    }
    #footer .footer-img{
        padding-bottom: .5rem;
    }
    
    #footer .footer-img img{
        border-radius: 15%;
        max-width: 120px;
        max-height: 120px;
    }
    
    #footer .footer-tagline{
        width: 100%;
        height: 25%;

    }
    
    #footer .footer-tagline h3{
        width: 100%;
        font-size: 1.5rem;
        text-align: center;
    }
    
    #footer .footer-container .footer-top .footer-info{
        display: none;
    }
    
    
    #footer .footer-container .footer-bot{
        height: 100%;
        width: 100%;
        padding-top: 0;
    }
    
    
    #footer .footer-container .footer-bot .footer-logo,
    #footer .footer-container .footer-bot .footer-links{
        height: 100%;
        width: 100%;
    }
    
    #footer .footer-container .footer-bot .footer-logo h3{
        text-align: center;
        font-size: 1rem;
        padding-left: 0;
    }
    
    #footer .footer-container .footer-bot .footer-links{
        width: 100%;
        padding-left: 0;
    }
    
    #footer .footer-container .footer-bot .footer-links a{
        text-decoration: none;
        letter-spacing: 3px;
        font-weight: 100;
        color: #fff;
        border: 2px solid transparent;
        padding: 5px 5px;
        text-align: center;
    }


}




@media (max-width: 767px) and (max-height: 450px)and (orientation:landscape) {
    #footer{
        height: 80vh;
        min-height: 310px;
        max-height: 325px;
    }
    
    #footer .footer-container{
        height: 90%;
        padding-top: 1rem;
        width: 80%;
    }
    
    #footer .footer-container .footer-top{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 175px;
        max-height: 190px;
    }
    #footer .footer-img{
        padding-bottom: .2rem;
    }
    
    #footer .footer-img img{
        border-radius: 15%;
        max-width: 120px;
        max-height: 120px;
    }
    
    #footer .footer-tagline{
        width: 100%;
        height: 20%;
    }
    
    #footer .footer-tagline h3{
        width: 100%;
        font-size: 1.5rem;
        text-align: center;
    }
    
    #footer .footer-container .footer-top .footer-info{
        display: none;
    }
    
    
    #footer .footer-container .footer-bot{
        height: 100%;
        width: 100%;
        padding-top: 0;
    }
    
    
    #footer .footer-container .footer-bot .footer-logo,
    #footer .footer-container .footer-bot .footer-links{
        height: 100%;
        width: 100%;
    }
    
    #footer .footer-container .footer-bot .footer-logo h3{
        text-align: center;
        font-size: 1.25rem;
        padding-left: 0;
    }
    
    #footer .footer-container .footer-bot .footer-links{
        width: 100%;
        padding-left: 0;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    #footer .footer-container .footer-bot .footer-links a{
        text-decoration: none;
        letter-spacing: 3px;
        font-weight: 100;
        color: #fff;
        border: 2px solid transparent;
        padding: 0 0 3px;
        text-align: center;
    }
    

}