:root{
  --primary-indigo: #6C60FA;
  --primary-blue: #0390F8;
  --primary-light-blue: #03BFF8;
  --secondary-pink: #FA60EE;
  --secondary-purple-pink: #DC60FA;
  --secondary-purple: #BF60FA;
  --primary-font:'Quicksand', sans-serif;

  --top-gradient: #6c60fa;
  --bootom-gradiant: #36307d;
  --header-background: #3f3f48;
  --screen-width: 1200px;

  --mobile-small: 3rem;
  --mobile-med: 3.5rem;
  --mobile-large: 4.75rem;

  --mobile-landscape: 4rem;

  --standard-small: 6rem;
  --standard-med: 6.5rem;
  --standard-large: 8.5rem;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body{
  font-family: var(--primary-font);
  color: #fff;
  max-width: 100%;
  overflow-x: hidden;

}

