#projects{
    width: 100%;
    height: 120vh;
    max-height: 900px;
    background: var(--header-background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
    overflow: hidden;

    .projects-title{
        margin: 1rem 0 1.5rem;
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-content: center;
        font-size: var(--vLarge-title);

        h1{
            text-transform: uppercase;
            font-size: var(--standard-large);
            font-weight: 100;
            letter-spacing: 10px;
        }
    }

    .project-container{
        max-width: var(--screen-width);
        height: 90%;
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: 800px;
    }

    .project-list{
        align-self: start;
        height: 80%;
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .project{
            width: 100%;
            height: 20%;
            max-height: 150px;
            cursor: pointer;
        }

        .future{
            cursor: initial;
        }
    }

    .project-display{
        height: 100%;
        width: 50%;
    }
}

@media screen and (min-width:1200px){
    #projects{
        padding-top: 2rem;
        height: 70vh;
        max-height: 1100px;
        min-height: 950px;
    }

}
@media screen and (min-width:1400px){
    #projects{
        height: 75vh;
        max-height: 1200px;
        min-height: 1050px;
    }


}
@media screen and (min-width:2000px){
    #projects{
        height: 70vh;
        max-height: 1100px;
        min-height: 950px;
    }

}



@media screen and (min-width:1025px) and (max-width:1199px){

    #projects{
        padding-bottom: 4rem;
        height: 150vh;
        min-height: 800px;
        max-height: 900px;

        .projects-title{
            padding-top: 0rem;
            margin-bottom: 0rem;
    
            h1{
                font-size: var(--standard-med);
            }
        }

        .project-container{
            height: 80%;
            max-height: 600px;
    
        }

        .project-list{
            height: 90%;
            width: 45%;
        }

        .project-display{
            width: 50%;
        }
    }
}

@media screen and (min-width:750px) and (max-width:1024px){

    #projects{
        width: 100%;
        height: 175vh;
        min-height: 475px;
        max-height: 900px;

        .projects-title{
            margin-bottom: 0.5rem;

            h1{
                font-size: var(--standard-small);
            }
        }

        .project-container{
            max-height: 600px;
        }

        .project-list{
            height: 90%;
            width: 45%;
            justify-content: space-evenly;
        }

        .project-display{
            width: 50%;
        }
    }

}


@media screen and (min-width: 600px) and (max-width:749px){
 
    #projects{
        height: 110vh;
        max-height: 900px;

        .projects-title{
            padding-top: 0rem;
            margin: 1rem;
            height: 15%;

            h1{
                font-size: var(--mobile-large);
            }
        }

        .project-container{
            flex-direction: column;
            justify-content: center;
            max-height: 700px;
    
        }

        .project-list{
            height: 50%;
            width: 100%;
            padding-bottom: 10px;
            flex-direction: row;
            justify-content: space-around;
            flex-wrap: wrap;

            .project{
                width: 49%;
                height: 30%;
                max-height: 200px;
            }
        }

        .project-display{
            height: 50%;
            width: 100%;
        }
    }
}

@media screen and (max-width:599px){
    #projects{
        height: 125vh;
        padding-bottom: 0;
        min-height: 750px;
        max-height: 800px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        .projects-title{
            padding-top: 0rem;
            margin: 1.5rem 0;
            height: 10%;

            h1{
                line-height: 1;
                font-size: var(--mobile-med);
            }
        }

        .project-container{
            height: 80%;
            max-height: 500px;
        }

        .project-list{
            min-height: 700px;
            width: 100%;
            justify-content: flex-start;

            .project{
                height: 15%;
                padding-bottom: 2rem;
                cursor: initial;

            }
        }

        .project-display{
            display: none;
         }
    }
}

@media screen and (max-width:350px){
    #projects{
        height: 125vh;
        min-height: 650px;
        max-height: 750px;

        .projects-title{
            padding-top: 0;
            margin: 1.5rem 0;
            height: 10%;

            h1{
                letter-spacing: 8px;
                font-size: var(--mobile-small);
            }
        }

        .project-container{
            height: 80%;
        }

        .project-list{
            min-height: 600px;

            .project{
                max-height: 200px;
                padding-bottom: 1rem;
            }
        }
    }
}


@media (max-width: 850px) and (max-height: 400px)and (orientation:landscape) {

    #projects{
        height:105vh;
        min-height: 375px;

        .projects-title{
            padding-top: 0rem;
            margin-bottom: 1rem;
            height: 20%;

            h1{
                font-size: 5rem;
            }
        }

        .project-container{
            flex-direction: column;
            justify-content: center;
            max-height: 600px;
        }

        .project-list{
            height: 80%;
            width: 100%;
            padding: 20px;
            flex-direction: row;
            justify-content: space-around;
            flex-wrap: wrap;
            align-items: center;

            .project{
                width: 49%;
                height: 50%;
                max-height: 200px;
            }
        }

        .project-display{
            display: none;
          }
    }
}



@media (max-width: 768px) and (max-height: 450px)and (orientation:landscape) {

    #projects{
        height: 140vh;
        max-height: 1000px;

        .projects-title{
            padding-top: 0rem;
            margin-bottom: 0rem;
            height: 20%;

            h1{
                font-size: var(--mobile-landscape);
            }
        }

        .project-container{
            flex-direction: column;
            justify-content: start;
            height: 80%;
            max-height: 500px;
            min-height: 200px;
        }

        .project-list{
            min-height: 200px;
            height: 90%;
            width: 100%;
            flex-direction: row;
            justify-content: space-around;
            flex-wrap: wrap;

            .project{
                width: 50%;
                height: 75%;
                max-height: 90px;
            }
        }

        .project-display{
            display: none;
          }
    }
}