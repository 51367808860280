#about{
    width: 100%;
    height: 130vh;
    background: var(--top-gradient);
    background: linear-gradient(0deg, rgba(54,48,125,1) 0%,rgba(108,96,250,1)100%);
    overflow: hidden;
    max-height: 1100px;

    .about-container{
        margin: 0 auto;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: var(--screen-width);
    
    }

    .about-heading{
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        padding-top: 1rem;

        h1{
            text-transform: uppercase;
            font-weight: 100;
            font-size: 6rem;
            letter-spacing: 8px;
        }
    }

    .about-timeline{
        height: 35%;
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .about-line{
        width: 80%;
        background: #fff;
        height: 1px;
        
        margin: 2rem 0 4rem;
    }

    .about-skills{
        margin: 0 auto;
        padding-top: 10px;
    
        height: 30%;
        width: 90%;
    }

}


@media (min-width: 320px){
    #about{
        height: 155vh;

        .about-container{
            justify-content: space-around;
        }

        .about-heading{
            height: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 1rem 0;

            h1{
                font-size: var(--mobile-small);
            }
        }
        
        .about-timeline{
            height: 45%;
        }

        .about-line{
            margin: 2rem 0 3rem;
        }

        .about-skills{
            height: 40%;
            width: 80%;
        }
    }
}

@media (min-width: 374px){
    #about{
        height: 160vh;

        .about-heading{
            height: 10%;
            align-items: center;
            margin: 1rem 0 0;

            h1{
                font-size: var(--mobile-med);
            }
        }

        .about-timeline{
            padding-top: 0rem;
            height: 50%;
            width: 100%;
        }

        .about-line{
            margin: 2rem 0 2.5rem;
        }

        .about-skills{
            height: 50%;
            width: 100%;
        }
    }
}

@media (min-width: 480px) {
 
    #about{
        height: 160vh;
        min-height: 100px;

        .about-heading{
            height: 20%;
            align-items: center;
            margin: 0;

            h1{
                font-size: var(--mobile-large);
            }
        }

        .about-timeline{
            padding-top: 0rem;
            height: 50%;
            width: 100%;
        }

        .about-line{
            margin: 2rem 0 2.5rem;
        }

        .about-skills{
            height: 50%;
            width: 100%;
        }
    }
}

@media (max-width: 570px) and (orientation:landscape) {
    #about{
        height: 200vh;
        min-height: 700px;
    }
}




@media (min-width: 768px) { 

    #about{
        height: 150vh;

        .about-heading{
            height: 15%;
            h1{
                font-size: var(--standard-small);
            }
        }

        .about-timeline{
            height: 30%;
        }

        .about-skills{
            height: 50%;
        }
    }
}


@media (min-width: 992px) { 
    #about{
        height: 140vh;

        .about-heading{
            align-items: flex-end;
            h1{
                font-size: var(--standard-med);
            }
        }

        .about-timeline{
            height: 30%;
            width: 90%;
        }
    }
}

@media screen and (min-width:1200px){

    #about{
        height: 155vh;
        min-height: 1250px;
        max-height: 1350px;

        .about-heading{
            width: 100%;
            height: 15%;
            display: flex;
            justify-content: center;
            margin: 1rem 0 0;

            h1{
                font-size: var(--standard-large);
            }
        }
        .about-timeline{
            height: 40%;
            width: 80%;
        }
    }
}


@media screen and (min-width:1440px){
    #about{
        height: 135vh;

        .about-container{
            min-width: 1400px;   
        }

        .about-heading{
            width: 80%;
        }

        .about-timeline{
            height: 35%;
            width: 70%;
        }

        .about-line{
            width: 90%;
        }

        .about-skills{
            margin: 0 auto;
            height: 40%;
            width: 100%;
        }
    }
}


@media screen and (min-width:2000px){

    #about{
        height: 75vh;
        max-height: 1500px;

        .about-heading{
            margin-top: 2rem;
        }

        .about-timeline{
            height: 40%;
            width: 75%;
        }

        .about-line{
            width: 90%;
        }
    }
}




@media (max-width: 850px) and (max-height: 415px)and (orientation:landscape) {
    #about{
        height: 275vh;
        min-height: 650px;
        max-height: 950px;

        .about-heading {
            max-height: 75px;
            display: flex;
            justify-content: center;
            align-items: center;

            h1{
                padding-top: 1rem;
                font-size: var(--mobile-landscape);
            }
        }

        .about-timeline{
            height: 20%;
            max-height: 185px;
            width: 100%;
        }

        .about-line{
            width: 80%;
        }

        about-skills{
            padding-top: 5px;
            margin: 0 auto;
            height: 50%;
            max-height: 450px;
        }
    }

}

